/**
 * Override relative paths in a standard NavItems configuration or Sitemap object with absolute URLs based on an array of override specifications.
 * @param  {Array} navItems  [{ href: {string}},
 * @param  {Array} overrides [{ match: {string | regex}, domain: {string}, }]
 * @return {Array}
 */
export default function addBaseUrlToNavigation(navItems, overrides) {
    const _addBaseUrlToNavigation = (item) => {
        let override = null;

        if (item.href && !item.href.startsWith('//') && !item.href.startsWith('http')) {
            override = overrides?.filter((i) => item.href.search(i.match) !== -1);
        } else if (item.items) {
            item.items.forEach(_addBaseUrlToNavigation);
        } else if (Array.isArray(item)) {
            item.forEach(_addBaseUrlToNavigation);
        }

        if (override?.length) {
            const href = `${override.pop().domain}${item.href}`;
            return Object.assign(item, { href });
        }
        return item;
    };
    return navItems.map(_addBaseUrlToNavigation);
}
